import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {

  function handleLoginTwitter() {

    const callbackUrl = 'http://localhost:3000';
    const url = `https://api.twitter.com/oauth/request_token?oauth_callback=${callbackUrl}`;
    
    axios.post('https://api.twitter.com/1.1/statuses/update.json?status=Hello%20world', {}, {
      headers: {
        authorization: `OAuth oauth_consumer_key=hlt21tAGq3UXjPlsnHklCihS5`,
        oauth_nonce: '$OAUTH_NONCE',
        oauth_signature: 'OAUTH_SIGNATURE',
        oauth_signature_method: "HMAC-SHA1",
        oauth_timestamp: 'OAUTH_TIMESTAMP',
        oauth_token: 'ACCESS_TOKEN',
        oauth_version: '1.0'
      }
    })
    .then(res => {
      console.log(res)
    })

    // axios.post(url, {}, {
    //   headers: {
    //     Authorization: `OAuth oauth_consumer_key=hlt21tAGq3UXjPlsnHklCihS5`,
    //     oauth_nonce: '$oauth_nonce',
    //     oauth_signature: 'oauth_signature',
    //     oauth_signature_method: "HMAC-SHA1",
    //     oauth_timestamp: '$timestamp',
    //     oauth_version: '1.0'
    //   }
    // })
    // .then(res => {
    //   console.log(res)
    // })
    
    // axios({
    //   method: 'post',
    //   url: `https://api.twitter.com/oauth/request_token?oauth_callback=${callbackUrl}`,
    //   data: {
    //     headers: {
          
    //     }
    //     oauth_callback: 'http://localhost:3000',
    //     x_auth_access_type: 'read',
    //   }
    // })
    // .then(res => {
    //   console.log(res)
    // })
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={handleLoginTwitter}>Click</button>
        
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
